import partnerImg1 from '../assets/images/partner4.png';
import partnerImg2 from '../assets/images/partner2.png';
import partnerImg3 from '../assets/images/partner3.jpg';

export const partnerData = [
  {
    id: 1,
    img: partnerImg1,
  },
  {
    id: 2,
    img: partnerImg2,
  },
  {
    id: 3,
    img: partnerImg3,
  },
  {
    id: 4,
    img: partnerImg1,
  },
  {
    id: 5,
    img: partnerImg2,
  },
  {
    id: 6,
    img: partnerImg3,
  },
];
