export const topTeachersData = [
  {
    id: 1,
    rating: 5.0,
    name: 'Alisher',
    surname: 'Navoiy',
    position: 'English Teacher',
  },
  {
    id: 2,
    rating: 4.7,
    name: 'Zokir',
    surname: 'Rasulov',
    position: 'Math Teacher',
  },
  {
    id: 3,
    rating: 4.9,
    name: 'Shodiyor',
    surname: "Toshpo'latov",
    position: 'English Teacher',
  },
  {
    id: 4,
    rating: 4.5,
    name: 'Gulchehra',
    surname: 'Sodiqova',
    position: 'History Teacher',
  },
  {
    id: 5,
    rating: 4.6,
    name: 'Olimjon',
    surname: "Turg'unov",
    position: 'History Teacher',
  },
  {
    id: 6,
    rating: 4.2,
    name: 'Madina',
    surname: 'Karimova',
    position: 'English Teacher',
  },
  {
    id: 7,
    rating: 4.1,
    name: 'Javlon',
    surname: 'Yusupov',
    position: 'Math Teacher',
  },
  {
    id: 8,
    rating: 3.8,
    name: 'Saida',
    surname: 'Niyozova',
    position: 'English Teacher',
  },
  {
    id: 9,
    rating: 3.6,
    name: 'Oybek',
    surname: 'Raxmatov',
    position: 'Biology Teacher',
  },
  {
    id: 10,
    rating: 2.5,
    name: 'Laziza',
    surname: 'Abdullaeva',
    position: 'Law Teacher',
  },
  {
    id: 11,
    rating: 4.8,
    name: 'Bekzod',
    surname: 'Shamsiyev',
    position: 'Physics Teacher',
  },
  {
    id: 12,
    rating: 4.3,
    name: 'Nigora',
    surname: 'Ismoilova',
    position: 'Chemistry Teacher',
  },
  {
    id: 13,
    rating: 4.0,
    name: 'Kamol',
    surname: 'Yuldashev',
    position: 'Math Teacher',
  },
  {
    id: 14,
    rating: 3.7,
    name: 'Nodira',
    surname: 'Tursunova',
    position: 'Geography Teacher',
  },
  {
    id: 15,
    rating: 3.5,
    name: 'Jahongir',
    surname: 'Yusufov',
    position: 'Computer Science Teacher',
  },
  // {
  //   id: 16,
  //   rating: 4.4,
  //   name: 'Dilnoza',
  //   surname: 'Qodirova',
  //   position: 'Art Teacher',
  // },
  // {
  //   id: 17,
  //   rating: 3.9,
  //   name: 'Azizbek',
  //   surname: 'Shukurov',
  //   position: 'Physical Education Teacher',
  // },
  // {
  //   id: 18,
  //   rating: 4.1,
  //   name: 'Maftuna',
  //   surname: 'Alimova',
  //   position: 'Music Teacher',
  // },
  // {
  //   id: 19,
  //   rating: 4.6,
  //   name: 'Mirjalol',
  //   surname: 'Rasulov',
  //   position: 'History Teacher',
  // },
  // {
  //   id: 20,
  //   rating: 4.0,
  //   name: 'Kamola',
  //   surname: 'Raxmatova',
  //   position: 'Geography Teacher',
  // },
  // {
  //   id: 21,
  //   rating: 3.4,
  //   name: 'Shukur',
  //   surname: 'Qodirov',
  //   position: 'Chemistry Teacher',
  // },
  // {
  //   id: 22,
  //   rating: 2.8,
  //   name: 'Sardor',
  //   surname: 'Nasriddinov',
  //   position: 'Physics Teacher',
  // },
  // {
  //   id: 23,
  //   rating: 3.3,
  //   name: 'Sayyora',
  //   surname: 'Berdieva',
  //   position: 'Biology Teacher',
  // },
  // {
  //   id: 24,
  //   rating: 3.1,
  //   name: 'Farrukh',
  //   surname: 'Yuldashev',
  //   position: 'Math Teacher',
  // },
  // {
  //   id: 25,
  //   rating: 3.0,
  //   name: 'Xurshida',
  //   surname: 'Yusufova',
  //   position: 'English Teacher',
  // },
  // {
  //   id: 26,
  //   rating: 2.9,
  //   name: 'Zilola',
  //   surname: 'Ismoilova',
  //   position: 'Art Teacher',
  // },
  // {
  //   id: 27,
  //   rating: 2.7,
  //   name: 'Ikrom',
  //   surname: 'Tojiboyev',
  //   position: 'Computer Science Teacher',
  // },
  // {
  //   id: 28,
  //   rating: 4.2,
  //   name: 'Aziza',
  //   surname: 'Karimova',
  //   position: 'History Teacher',
  // },
  // {
  //   id: 29,
  //   rating: 3.9,
  //   name: 'Muxammad',
  //   surname: 'Nematov',
  //   position: 'Physical Education Teacher',
  // },
  // {
  //   id: 30,
  //   rating: 2.6,
  //   name: 'Malika',
  //   surname: 'Abdullaeva',
  //   position: 'Law Teacher',
  // },
];
