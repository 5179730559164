import React from 'react';

import Pages from '../global/routes';

const Root = () => {
  return (
    <>
      <Pages />
    </>
  );
};

export default Root;
